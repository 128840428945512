/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dropdown_close': {
    width: 10,
    height: 6,
    viewBox: '0 0 10 6',
    data: '<path pid="0" _fill="#1C1E42" d="M5.012 2.205L1.378 5.917l-1.06-1.06L5.09.084l4.773 4.773-1.061 1.06z"/>'
  }
})
